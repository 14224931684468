import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import axios from 'axios'
import Cookies from 'universal-cookie'

const service = axios.create({
    baseURL: process.env.API_URL,
    delayed: true
})

// Add a request interceptor
service.interceptors.request.use(
    function (config) {
        if (config.headers.url !== '/account/sign-in-with-email') {
            const cookies = new Cookies()

            const token = cookies.get('session')
            config.headers.Authorization = 'Bearer ' + token
        }
        NProgress.start()

        return config
    },
    function (error) {
        NProgress.done()
        return Promise.reject(error)
    }
)

// Add a response interceptor
service.interceptors.response.use(
    function (response) {
        NProgress.done()
        return response
    },
    function (error) {
        NProgress.done()
        if (error.response.status === 401) {
            const cookies = new Cookies()

            cookies.remove('session')
            localStorage.removeItem('name')
            window.location.href = '/login'
        }
        return Promise.reject(error)
    }
)

export default service
