export const formModel = {
    cnpj: '',
    password: ''
}

export const fieldsValidator = {
    cnpj: {
        rules: ['required', 'cnpj']
    },
    password: {
        rules: ['required']
    }
}
