import React, { useState, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
    Box,
    Button,
    Text,
    InputPassword,
    CnpjInput,
    InputCheckbox,
    Spinner,
    Switcher
} from 'condolivre-ds'
import styles from './index.module.scss'
import { authenticate, requestForgotPassword } from './network'
import { formModel, fieldsValidator } from './model'
import { validatorModel } from '@/helpers/validator'
import { onlyDigits } from '@/helpers/format'
import BaseIntroPage from '@/components/BaseIntroPage/BaseIntroPage'
import ForgotPasswordSuccess from '@/pages/login/ForgotPasswordSuccess'
import Head from 'next/head'
import { LoginPageState } from '@/pages/login/constants'
import { useAuth } from '@/providers/Auth'
import { ROUTE_DASHBOARD, ROUTE_WELCOME } from '@/config/routes'
import { getUser } from '@/helpers/cookies'
import { SupplierStatus } from '@/pages/supplier/constants'
import { Hidden, Visible } from 'react-grid-system'
import { gtmShootEvent, tagScreen } from '@/helpers/tag'

export default function Login() {
    const router = useRouter()
    const authContext = useAuth()
    const isAuthenticated = authContext.isAuthenticated

    const [processing, setProcessing] = useState(false)
    const [forgotProcessing, setForgotProcessing] = useState(false)
    const [form, setForm] = useState(formModel)
    const [formError, setFormError] = useState({})
    const [enabledLoginButton, setEnabledLoginButton] = useState(false)

    const [pageState, setPageState] = useState(LoginPageState.FORM_LOGIN)

    // Forgot Password
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')

    const validationModel = useMemo(() => {
        return validatorModel(fieldsValidator)
    }, [])

    useEffect(() => {
        isAuthenticated && router.push(ROUTE_DASHBOARD)
        if ((window?.location?.host || '').startsWith('fornecedores.')) {
            window.location = window.location.href.replace('fornecedores.', 'fornecedor.')
        }
    }, [])

    useEffect(() => {
        setEnabledLoginButton(form.cnpj.length && form.password.length)
    }, [form])

    const validate = (fields = []) => {
        const { result, errorMessages } = validationModel.validate(form, fields)
        setFormError(errorMessages)
        return result
    }

    const handleInput = (field) => (e) => {
        setForm({
            ...form,
            [field]: e.target ? e.target.value : e.value
        })
    }

    const handleSubmit = (cnpj, password) => {
        setProcessing(true)
        authenticate(onlyDigits(cnpj), password)
            .then((response) => {
                if (response.success) {
                    authContext.setAuthenticated(true)
                    const user = getUser()
                    user.status === SupplierStatus.QUALIFIED.name
                        ? router.push(ROUTE_WELCOME)
                        : router.push(ROUTE_DASHBOARD)
                } else {
                    setProcessing(false)
                    setFormError({ cnpj: response.msg })
                    gtmShootEvent({
                        screen: tagScreen.SUPPLY_LOGIN,
                        label: 'txt-erro usuario ou senha invalido.'
                    })
                }
            })
            .catch((e) => {
                setFormError({ cnpj: e.msg })
                setProcessing(false)
            })
    }

    const shootEvent = (id) => () => {
        gtmShootEvent({
            screen: tagScreen.SUPPLY_LOGIN,
            label: id
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            handleSubmit(form.cnpj, form.password)
        } else {
            gtmShootEvent({
                screen: tagScreen.SUPPLY_LOGIN,
                label: 'txt-erro cnpj invalido'
            })
        }
    }

    const handleForgotPassword = () => {
        gtmShootEvent({
            screen: tagScreen.SUPPLY_RESET_PASSWORD_EMAIL_RETURN,
            label: 'btn-esqueci-minha-senha'
        })

        if (validate(['cnpj'])) {
            setForgotProcessing(true)
            requestForgotPassword(form.cnpj)
                .then((response) => {
                    if (response.success) {
                        setForgotPasswordEmail(response.data.email)
                        setPageState(LoginPageState.FORGOT_PASSWORD_SUCCESS)
                    } else {
                        setFormError({ cnpj: response.msg })
                        setPageState(LoginPageState.FORM_LOGIN)
                    }
                    setForgotProcessing(false)
                })
                .catch((e) => {
                    setFormError({ cnpj: e.msg })
                    setPageState(LoginPageState.FORM_LOGIN)
                    setForgotProcessing(false)
                })
        }
    }

    // Should migrate to a component?
    const generatingProcessingLoader = () => {
        return (
            <div className={styles.loadingBox}>
                <Spinner size={'50px'} color={'blue_600'} />
                <Text color="black" size="v_20" weight={'v_600'} className={styles.titleLoading}>
                    Estamos processando sua solicitação...
                </Text>
                <Text color="black" size="v_18" weight={'v_500'} className={styles.subtitleLoading}>
                    Por favor aguarde.
                </Text>
            </div>
        )
    }

    //Components
    const generateFormLogin = () => {
        return (
            <Box className={styles.boxLogin}>
                <Text color="black" weight="v_500" size="v_30" className={styles.title}>
                    Seja bem-vindo!
                </Text>
                <Text color="gray_700" weight="v_400" size="v_16" className={styles.sub}>
                    Insira seus dados para acessar a plataforma
                </Text>
                <form onSubmit={onSubmit}>
                    <CnpjInput
                        label={'Login (CNPJ)'}
                        onChange={handleInput('cnpj')}
                        onClick={shootEvent('txt-cnpj')}
                        error={formError.cnpj}
                        value={form.cnpj}
                        name={'cnpj'}
                        errorMessage={formError.cnpj}
                        id="cnpj"
                    />

                    <Hidden xs>
                        <InputCheckbox
                            label={'Manter meu usuário salvo neste dispositivo'}
                            color={'gray_700'}
                        />
                    </Hidden>
                    <Visible xs>
                        <Switcher size={['34px', '20px']} className={styles.switcher}>
                            <Text size={'v_14'} weight={'v_500'} color={'gray_700'}>
                                Manter meu usuário salvo neste dispositivo
                            </Text>
                        </Switcher>
                    </Visible>

                    <InputPassword
                        name="password"
                        id="password"
                        className={styles.passwordField}
                        value={form.password}
                        onClick={shootEvent('txt-password')}
                        onChange={handleInput('password')}
                        error={formError.password}
                        errorMessage={formError.password}
                        label="Senha"
                    />

                    <Text
                        size={'v_12'}
                        color={'blue_600'}
                        weight={'bold'}
                        data-testid={'forgot-password'}
                        onClick={handleForgotPassword}
                        className={styles.forgotPassword}>
                        Esqueci minha senha
                    </Text>
                    <Button
                        onClick={shootEvent('btn-acessar-plataforma')}
                        variant="primary"
                        size={'normal'}
                        disabled={!enabledLoginButton || processing}
                        data-testid={'login-btn'}>
                        Acessar a plataforma
                    </Button>
                    <a href={'/pre-register'}>
                        <Text
                            size={'v_12'}
                            color={'blue_600'}
                            weight={'bold'}
                            data-testid={'pre-register-link'}
                            className={styles.preRegisterLink}>
                            Cadastre-se aqui!
                        </Text>
                    </a>
                </form>

                {forgotProcessing && generatingProcessingLoader()}
            </Box>
        )
    }

    const generateSuccessForgotPassword = () => {
        return (
            <ForgotPasswordSuccess
                email={forgotPasswordEmail}
                backAction={() => {
                    setPageState(LoginPageState.FORM_LOGIN)
                }}
                cnpj={form.cnpj}
            />
        )
    }

    return (
        <BaseIntroPage showPreRegister>
            <Head>
                <title>Login | Condolivre</title>
            </Head>
            {pageState === LoginPageState.FORM_LOGIN && generateFormLogin()}
            {pageState === LoginPageState.FORGOT_PASSWORD_SUCCESS &&
                generateSuccessForgotPassword()}
        </BaseIntroPage>
    )
}
