import React from 'react'
import { IconEmojiWarning, Text, IconWhats } from 'condolivre-ds'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import BoxInfo from '@/components/BoxInfo/BoxInfo'
import { onlyDigits } from '@/helpers/format'
import { gtmShootEvent, tagScreen } from '@/helpers/tag'

const shootEvent = (id) => () => {
    gtmShootEvent({
        screen: tagScreen.SUPPLY_RESET_PASSWORD_EMAIL_RETURN,
        label: id
    })
}

export default function ForgotPasswordSuccess({ email, backAction, cnpj }) {
    const whatsappLabel = email ? 'Não é seu email?' : 'Email não encontrado'
    const whatsappMessage = email
        ? `Olá,+gostaria+de+recuperar+minha+senha,+mas+não+tenho+acesso+ao+email+indicado,+meu+CNPJ+é+${onlyDigits(
              cnpj
          )}`
        : `Olá,+gostaria+de+recuperar+minha+senha,+mas+meu+email+não+foi+encontrado,+meu+CNPJ+é+${onlyDigits(
              cnpj
          )}`

    return (
        <BoxInfo
            backAction={backAction}
            className={[styles.boxLogin, styles.success, styles.whatsappLink].join(' ')}>
            <IconEmojiWarning />
            <Text weight="v_500" size="v_26" color="black">
                Esqueceu sua senha?
            </Text>
            {email && (
                <>
                    <Text size="v_18" color="gray_700" weight="v_400">
                        Enviamos um link para redefinição de senha para o seu email:
                    </Text>
                    <Text size="v_20" color="black" weight="v_600">
                        {email}
                    </Text>
                </>
            )}

            <Text size="v_18" color="gray_700" weight="v_400">
                <strong>{whatsappLabel}</strong>
                <br /> entre em contato com nossa equipe através do WhatsApp abaixo
            </Text>

            <div>
                <Text size={'v_26'} weight={'v_600'} color={'gray_800'}>
                    <IconWhats />
                    <a
                        href={`https://api.whatsapp.com/send/?phone=5511942232039&text=${whatsappMessage}`}
                        onClick={shootEvent('btn-whatsapp')}
                        target="_blank"
                        rel="noreferrer">
                        (11) 98084-1487
                    </a>
                </Text>
            </div>
        </BoxInfo>
    )
}

ForgotPasswordSuccess.propTypes = {
    email: PropTypes.string,
    backAction: PropTypes.func,
    cnpj: PropTypes.string
}
